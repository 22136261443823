import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import BreadCrumbs from '@objects/breadcrumb'
import Headline from '@objects/headline'
import { FormattedMessage, useIntl } from 'react-intl'
import FontSize from '@config/theme/definitions/fontSize'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Copy from '@components/copy'

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    textAlign: 'center',
    paddingTop: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headerIcon: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    '& svg': {
      height: '84px',
      width: '84px',
    },
  },
  underlinedBtn: {
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    padding: theme.spacing(0, 0, 1),
    fontSize: FontSize['base'],
    '& svg': {
      height: FontSize['3lg'],
      width: 'auto',
    },
  },
  headlineWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headline: {
    marginBottom: theme.spacing(4),
  },
}))

export type ThanksProps = {
  data: {
    thanks: {
      copy: {
        raw: string
      }
    }
  }
}
export default function RootIndex({ data }: ThanksProps): ReactElement {
  const thanks = get(data, 'thanks.copy')
  const classes = useStyles()
  const intl = useIntl()

  return (
    <section>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs
          crumbList={[
            {
              label: intl.formatMessage({
                id: 'navigation.breadcrumb.events.thanks.label',
              }),
              link: intl.formatMessage({
                id: 'navigation.breadcrumb.events.thanks.url',
              }),
            },
          ]}
        />
      </Container>

      <Container className={classes.headlineWrapper} width={'sm'}>
        <Headline className={classes.headline} level={1}>
          <FormattedMessage id={'events.form.title'} />
        </Headline>
        <Copy richtext={thanks} />
      </Container>
    </section>
  )
}

export const pageQuery = graphql`
  query {
    thanks: contentfulModuleText(
      id: { eq: "e936ecf7-f802-5934-99c6-c67852618e66" }
    ) {
      copy {
        raw
      }
    }
  }
`
